import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import Cookies from 'js-cookie';

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes";
import { loginSuccess, logoutUserSuccess, apiError } from "./actions";

//Include Both Helper File with needed methods
import { toast } from "react-toastify";

import { post } from "../../../helpers/apiMethods";

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(post, "/superAdmin/auth/login", {
      email: user.email,
      password: user.password,
    });
    let token = response.data.token.accessToken;
    console.log("yahpo "+response.data.user)
  let username ="";
    if  (response.data.user.firstName)
  {  username = response.data.user.firstName   ;}
  else {
     username = response.data.user.type
  }
    Cookies.set('S_A_TOKEN', token, { expires: 7 });
    localStorage.setItem("token",token)
    localStorage.setItem("username",username)
    history.push("/dashboard");
    yield put(loginSuccess(response));

    toast.success('Vous êtes authentifié', {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
    } catch (error) {
      yield put(apiError(error.message));
      toast.error('email ou mot de passe non valide ', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
   
        });
}}


function* logoutUser({ payload: { history } }) {
  try {
    Cookies.remove('S_A_TOKEN');

   
    history.push("/login");
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchUserLogin() {
  yield takeEvery(LOGIN_USER, loginUser);
}

export function* watchUserLogout() {
  yield takeEvery(LOGOUT_USER, logoutUser);
}

function* authSaga() {
  yield all([fork(watchUserLogin), fork(watchUserLogout)]);
}

export default authSaga;
