import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch, useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { FormGroup, Label } from "reactstrap";
import {
  getAdmins,
  toggleAdmin,
  deleteAdminsInformation,
  disableEdit,
  enableEdit,
  retrieveAdmin,
  getMedecinAdmins,
} from "../../store/admins/actions";
import Select from "react-select";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Button, Modal } from "reactstrap";
import { MDBBtn } from "mdbreact";
import SpinnerPage from "../../components/loader";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withNamespaces } from "react-i18next";
import "../Tables/datatables.scss";
import { AVAILABLE_ADMIN, NON_AVAILABLE_ADMIN } from "../../store/admins/types";
import DeleteConfirmation from "../../components/Common/DeleteConfirmation";

const typeOptionGroup = [
  {
    label: "Tous",
    value: "all",
  },
  {
    label: "Medecin Référent ",
    value: "medecinResponsable",
  },
  {
    label: "Medecin Expert",
    value: "medecinTeleexpert",
  },
  {
    label: "Medecin Requérant ",
    value: "medecin",
  },
];
const columns = [
  
  {
    label: "#",
    field: "number",
    width: 50,
  },


  {
    label: "Nom",
    field: "lastName",
    width: 200,
  },
  {
    label: "Prénom",
    field: "firstName",
    width: 200,
  },
  {
    label: "Email",
    field: "email",
    width: 200,
  },
  {
    label: "Num. Téléphone",
    field: "phone",
    sort: "disabled",
    width: 150,
  },
  {
    label: "Type",
    field: "type",
    sort: "asc",
    width: 100,
  },

  {
    label: "Status",
    field: "status",
    sort: "asc",
    width: 150,
  },
  {
    label: "Actions",
    field: "actions",
    sort: "disabled",
    width: 100,
  },
];

const AdminList = () => {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const history = useHistory();
  const availability = eval(localStorage.getItem("availability"));
  const { baseList, loading } = useSelector(({ Admins }) => Admins);
  const [toggleAvailability, setToggleAvailability] = useState(
    availability !== null ? availability : true
  );
  const [x, setX] = useState(typeOptionGroup[0]);
  const { control, errors } = useForm();
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const toggleDeleteConfirmationModal = () =>
    setDeleteConfirmationOpen(!deleteConfirmationOpen);
  const [selectedId, setSelectedId] = useState(null);

  useEffect(() => {
    dispatch(getAdmins());
    dispatch(getMedecinAdmins());
  }, []);

  const handleAddClick = () => {
    history.push(`${match.url}/create`);
  };

  const handleDeleteClick = () => {
    dispatch(
      deleteAdminsInformation(
        selectedId,
        toggleAvailability ? baseList : baseList,
        toggleAvailability ? AVAILABLE_ADMIN : NON_AVAILABLE_ADMIN
      )
    );
  };

  const handleEditClick = (item) => {
    dispatch(enableEdit(item._id));
    dispatch(retrieveAdmin(item._id));

    localStorage.setItem("availability", toggleAvailability);
    history.push(`/admins/${item._id}`);
  };
  const handleChange = (val) => {
    setX(val);
  };

  const mapData = (x) => {
    let rowsToDisplay = baseList.map((item, index) => {
      return {
        number: index + 1,
        firstName: item?.firstName,
        lastName: item?.lastName,
        email: item?.email,
        type: item?.type === "medecinTeleexpert" ? "Medecin Expert" : item?.type ,
        phone: item?.phone,
        status: item?.activated ? (
          <MDBBtn
            pill="true"
            size="sm"
            color="danger"
            onClick={() => {
              dispatch(
                toggleAdmin(
                  item,
                  toggleAvailability ? AVAILABLE_ADMIN : NON_AVAILABLE_ADMIN
                )
              );
            }}
          >
            <i className="fas fa-toggle-off mr-1"></i>
            Désactiver
          </MDBBtn>
        ) : (
          <MDBBtn
            pill="true"
            size="sm"
            color="success"
            onClick={() => {
              dispatch(
                toggleAdmin(
                  item,
                  toggleAvailability ? AVAILABLE_ADMIN : NON_AVAILABLE_ADMIN
                )
              );
            }}
          >
            <i className="fas fa-toggle-on mr-1"></i>
            Activer
          </MDBBtn>
        ),
        actions: (
          <Fragment>
            <button
              className="btn btn-primary waves-effect waves-light mt-1"
              value={item?._id}
              onClick={() => handleEditClick(item)}
            >
              <i className="mdi mdi-lead-pencil"></i>
            </button>
            &nbsp;
            <button
              className="waves-effect waves-light btn btn-danger mt-1"
              value={item?._id}
              onClick={() => {
                setSelectedId(item?._id);
                toggleDeleteConfirmationModal();
              }}
            >
              <i className="fa fa-trash"></i>
            </button>
            ​
          </Fragment>
        ),
      };
    });
    if (x.value === "all") {
      console.log("rowsToDisplayaaab ", rowsToDisplay);
    } else {
      console.log("rowsToDisplay");
    }

    switch (x.value) {
      case "all":
        return {
          columns: columns,
          rows: rowsToDisplay,
        };
      default:
        return {
          columns: columns,
          rows: rowsToDisplay.filter((iot) => iot.type === x.value),
        };
    }
  };
  return (
    <Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Admins" breadcrumbItem="Liste" />
          <Row>
            <Col className="col-12">
              <h1 className="my-4">Utilisateurs</h1>

              <Card>
                <CardBody>
                  {/* {console.log("hh", mapData(x, "medecinTeleexpert"))} */}
                  <Row className="mb-2">
                    <Col sm="4"></Col>
                    <Col sm="8">
                      <div className="text-sm-right">
                        <Button
                          onClick={() => {
                            dispatch(disableEdit());
                            handleAddClick();
                          }}
                          type="button"
                          color="primary"
                          className="btn-rounded waves-effect waves-light mb-2 mr-2"
                        >
                          <i className="mdi mdi-plus mr-1"></i>{" "}
                          Ajouter&nbsp;&nbsp;{" "}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <div className="mb-4">
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="type">Type</Label>
                        <Controller
                          name="type"
                          control={control}
                          render={({ value, ref }) => (
                            <Select
                              inputRef={ref}
                              classNamePrefix="addl-class"
                              options={typeOptionGroup}
                              value={typeOptionGroup.find(
                                (c) => c.value === value
                              )}
                              defaultValue={typeOptionGroup[0]}
                              onChange={(val) => {
                                handleChange(val);
                              }}
                            />
                          )}
                        />
                        <span className="my-2 text-danger">
                          {errors.gender && errors.type?.message}
                        </span>
                      </FormGroup>
                    </Col>
                  </div>

                  <MDBDataTable
                    responsive
                    hover={true}
                    noBottomColumns={true}
                    striped
                    bordered
                    data={mapData(x)}
                    className="text-center"
                    disableRetreatAfterSorting={true}
                    noRecordsFoundLabel={
                      loading ? <SpinnerPage /> : "Aucune entrée trouvée !"
                    }
                    entriesLabel="Afficher entrées"
                    searchLabel="Rechercher"
                    infoLabel={["Affichage de", "a", "parmis", "entrées"]}
                    paginationLabel={["Précédent", "Suivant"]}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

      {/**DeleteConfirmation MODAL ! */}

      <Modal
        isOpen={deleteConfirmationOpen}
        role="dialog"
        size="LG"
        autoFocus={true}
        centered={true}
        id={`confirmation_Modal`}
        tabIndex="-1"
        toggle={toggleDeleteConfirmationModal}
      >
        <DeleteConfirmation
          deleteAction={handleDeleteClick}
          toggleModal={toggleDeleteConfirmationModal}
        />
      </Modal>
    </Fragment>
  );
};
export default withNamespaces()(AdminList);
