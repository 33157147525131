import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { toast } from 'react-toastify';
import {
  GET_FUNCTIONAL_ADMIN_LIST,
  ADD_FUNCTIONAL_ADMIN,
  TOGGLE_ADMIN,
  EDIT_FUNCTIONAL_AMDIN,
  DELETE_ADMINS_INFORMATION,
  AVAILABLE_ADMIN,
  GET_MEDECIN_ADMIN,
  RETRIEVE_ADMIN
} from "./types";

import { get, post, remove, update } from "../../helpers/apiMethods";
import {
  addAdminSuccess,
  editAdminSuccess,
  getMedecinAdminsSuccess,
  getAdminsSuccess,
  toggleAdminSuccess,
  deleteAdminsInformationSuccess,
  retrieveAdminSuccess,
} from "./actions";

const adminsApi = "/superAdmin/admin";
const adminsdelete ="/users";

function* getAdminsListApi() {
  try {
    const response = yield call(get, `${adminsApi}/get-admins`);
    yield put(getAdminsSuccess(response.data));
  } catch (error) { 
    console.error(error);
  }
}

function* deleteAdminsInformation({ payload }) {
  try {
    const { id, oldList,adminType } = payload;
    yield call(remove, `${adminsdelete}/${id}`);
    const newList = oldList.filter(item => item._id !== id);
    adminType === AVAILABLE_ADMIN ? 
    yield put(deleteAdminsInformationSuccess(newList))
      : 
    yield put(deleteAdminsInformationSuccess(newList));
    console.log(newList)
    toast.success("✅ L'opération a été effectuée avec succées !", {
      position: "top-right",
      autoClose: 2500
    });
  } catch (error) {
    console.error(error);
    toast.error("⛔ Une erreur est survenue, l'opération n'a pas pu étre effectuée.", {
      position: "top-right",
      autoClose: 2500
    });
  }
}

function* getMedecinAdminsApi() {
  try {
    const response = yield call(get, `${adminsApi}/medecins`);
    yield put(getMedecinAdminsSuccess(response.data));
  } catch (error) {
    console.error(error);
  }
}

function* switchAdminApi({ payload }) {
  try {
    const response = yield call(update, `${adminsApi}/${payload.data._id}`, {
      activated: !payload.data.activated,
    });
    console.log(payload)
    
      yield put(toggleAdminSuccess(response))
     
  } catch (error) {
    console.error(error);
    toast.error("⛔ Une erreur est survenue, l'opération n'a pas pu étre effectuée.", {
      position: "top-right",
      autoClose: 2500
    });
  }
}

function* editAdminApi({ payload }) {
  try {
    const { oldList, id, adminData, adminType } = payload;
    const response = yield call(
      update,
        `${adminsApi}/${id}`,
        adminData
      );

      let newList = oldList.map((admin) => (admin.id === response.id) ? response : admin);

     
      yield put(editAdminSuccess(newList))
     

      toast.success("✅ L'opération a été effectuée avec succées !", {
        position: "top-right",
        autoClose: 2500
      });
    } catch (error) {
    console.error(error);
    toast.error("⛔ Une erreur est survenue, l'opération n'a pas pu étre effectuée.", {
      position: "top-right",
      autoClose: 2500
    });
  }
}

function* addAdminApi({ payload }) {
  try {
    const { adminData, oldList } = payload;

    const response = yield call(post, `${adminsApi}/registerResponsable`, adminData);

    oldList.unshift(response);
    console.table(oldList);
    
    yield put(addAdminSuccess(oldList));
    toast.success("✅ L'opération a été effectuée avec succées !", {
      position: "top-right",
      autoClose: 2500
    });
  } catch (error) {
    console.error(error)
    if (error.code===409){
      toast.error("⛔ Une erreur est survenue, User existe déja.", {
        position: "top-right",
        autoClose: 2500
      });


    }

   else {toast.error("⛔ Une erreur est survenue, l'opération n'a pas pu étre effectuée.", {
      position: "top-right",
      autoClose: 2500
    });}
  }
}

function* retrieveAdminInformation({payload}) {
  try {
    const response = yield call(get, `${adminsApi}/${payload}`);
    yield put(retrieveAdminSuccess(response));
  } catch (error) { 
    console.error(error);
    toast.error("⛔ Une erreur est survenue, l'opération n'a pas pu étre effectuée.", {
      position: "top-right",
      autoClose: 2500
    });
  }
}
export function* retrieveAdminInformationSaga() {
  yield takeEvery(RETRIEVE_ADMIN, retrieveAdminInformation);
}

export function* deleteAdminsSaga() {
  yield takeEvery(DELETE_ADMINS_INFORMATION, deleteAdminsInformation);
}

export function* editAdmins() {
  yield takeEvery(EDIT_FUNCTIONAL_AMDIN, editAdminApi);
}


export function* getMedecinAdmins() {
  yield takeEvery(GET_MEDECIN_ADMIN, getMedecinAdminsApi);
}


export function* switchAdminStatus() {
  yield takeEvery(TOGGLE_ADMIN, switchAdminApi);
}
export function* getAdminsList() {
  yield takeEvery(GET_FUNCTIONAL_ADMIN_LIST, getAdminsListApi);
}
export function* AddNewAdmin() {
  yield takeEvery(ADD_FUNCTIONAL_ADMIN, addAdminApi);
}

function* AdminSaga() {
  yield all([
    fork(getAdminsList),
    fork(AddNewAdmin),
    fork(getMedecinAdmins),
    fork(switchAdminStatus),
    fork(editAdmins),
    fork(deleteAdminsSaga),
    fork(retrieveAdminInformationSaga)
  ]);
}

export default AdminSaga;