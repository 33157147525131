import React from "react";
import { Row, Col } from "reactstrap";

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer"  style={{zIndex:9}}>
        <div className="container-fluid">
          <Row>
          <Col md="6">
              <div className=" copyright">
                © {new Date().getFullYear()}{" "}
                <a
                  href="https://www.katomi.co/en/"
                  target="_blank"
                >
                  Katomi.co
                </a>
                
              </div>
            </Col>
            <Col sm={6}>
              {/* <div className="text-sm-right d-none d-sm-block">
                Design & Develop by Themesbrand
              </div> */}
            </Col>
          </Row>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
