import {
  GET_FUNCTIONAL_ADMIN_LIST,
  GET_FUNCTIONAL_ADMIN_LIST_SUCCESS,
  ADD_FUNCTIONAL_ADMIN,
  ADD_FUNCTIONAL_ADMIN_SUCCESS,
  TOGGLE_ADMIN,
  TOGGLE_ADMIN_SUCCESS,
  EDIT_FUNCTIONAL_AMDIN,
  EDIT_FUNCTIONAL_ADMIN_SUCCESS,
  DELETE_ADMINS_INFORMATION,
  DELETE_ADMINS_INFORMATION_SUCCESS,
  RETRIEVE_ADMIN,
  RETRIEVE_ADMIN_SUCCESS,
  ENABLE_EDIT,
  DISABLE_EDIT,
  GET_MEDECIN_ADMIN,
  GET_MEDECIN_ADMIN_SUCCESS,
} from "./types";

const initAdminState = {
  stepNumber: 0,
  loading: false,
  baseList: [],
  medecinList: [],
  editMode: false,
  selectedAdmin: {},
};

const Admins = (state = initAdminState, action) => {
  let { payload, type } = action;

  switch (type) {
    case GET_FUNCTIONAL_ADMIN_LIST:
      return {
        ...state,
        loading: true,
      };
    case GET_FUNCTIONAL_ADMIN_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        baseList: payload,
      };
    case GET_MEDECIN_ADMIN:
      return {
        ...state,
        loading: true,
      };
    case GET_MEDECIN_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        medecinList: payload,
      };
    case RETRIEVE_ADMIN:
      return {
        ...state,
        loading: true,
      };
    case RETRIEVE_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedAdmin: payload,
      };
    case ENABLE_EDIT:
      return {
        ...state,
        editMode: true,
      };
    case DISABLE_EDIT:
      return {
        ...state,
        editMode: false,
      };
    case TOGGLE_ADMIN:
      return {
        ...state,
      };
    case TOGGLE_ADMIN_SUCCESS:
      let { baseList } = state;
      let newList = baseList.map((elm) => {
        if (elm._id === payload.id) {
          elm.activated = payload.activated;
        }
        return elm;
      });
      return {
        ...state,
        loading: false,

        baseList: newList,
      };
    case ADD_FUNCTIONAL_ADMIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_FUNCTIONAL_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        baseList: payload,
      };
    case DELETE_ADMINS_INFORMATION:
      return {
        ...state,
        loading: true,
      };
    case DELETE_ADMINS_INFORMATION_SUCCESS:
      return {
        ...state,
        baseList: payload,
        loading: false,
      };
    case EDIT_FUNCTIONAL_AMDIN:
      return { ...state, loading: true };
    case EDIT_FUNCTIONAL_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        baseList: payload,
      };
    default:
      return state;
  }
};

export default Admins;
