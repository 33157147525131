import React from 'react'
import Dropzone from 'react-dropzone';

/**
 * props : 
 *  imageToDisplay: 
 *    Type => Boolean.
 *    Purpose => Stores the link for the image that we want to dispaly inside the dropzone ;
 *      null -> display the instruction text.
 *      !null -> display the picture.
 * --------------------------------------------------------------------------
 *  setImageToDisplay :
 *    Type => Function.
 *    Purpose => Sets the imageToDisplay variable to the selected picture.
 * --------------------------------------------------------------------------
 *  setImageToggle :
 *    Type => Function.
 *    Purpose => When set to true, it tells the parent that we're going to send the selected picture.
 * --------------------------------------------------------------------------
 *  onChange :
 *    Type => Function.
 *    Purpose => Gets the selected picture (in this case used with the "react-hook-form" Controller).
 *  */ 
function FileDropzone({ imageToDisplay, setImageToggle, setImageToDisplay, onChange }) {
  const formatBytes = (bytes, decimals = 2) => {
    if(setImageToggle) setImageToggle(true);

    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  return (
    <div className="ml-1 mb-1" style={{width:'100%', height:'100%'}}>
      <Dropzone
        onDrop={(acceptedFiles) => {
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
              formattedSize: formatBytes(file.size),
            })
          );
          onChange(acceptedFiles);
          setImageToDisplay(acceptedFiles[0].preview);
        }}
      >
      {({ getRootProps, getInputProps }) => (
        <div 
          style={{
            backgroundImage:
            (imageToDisplay) ? `url(${imageToDisplay})` : '',
            backgroundSize: `100% 100%`
          }}
          className="dropzone">
          <div
            className="dz-message needsclick"
            {...getRootProps()} style={{height:'250px'}}>
              <input {...getInputProps()} />
              {(!imageToDisplay)
              &&
              (<div style={{ padding:'5%'}} className="dz-message needsclick">
                <div className="mb-1">
                  <i className="display-4 text-muted bx bxs-cloud-upload"></i>
                </div>
                <h6>Glissez un fichier ici ou bien cliquez pour choisir.</h6>
              </div>)
              }
          </div>
        </div>
      )}
      </Dropzone>
    </div>
  )
}

export default FileDropzone;

