export const ADD_FUNCTIONAL_ADMIN = "ADD_FUNCTIONAL_ADMIN";
export const ADD_FUNCTIONAL_ADMIN_SUCCESS = "ADD_FUNCTIONAL_ADMIN_SUCCESS";

export const EDIT_FUNCTIONAL_AMDIN = "EDIT_FUNCTIONAL_ADMIN";
export const EDIT_FUNCTIONAL_ADMIN_SUCCESS = "EDIT_FUNCTIONAL_ADMIN_SUCCESS";


export const DELETE_FUNCTIONAL_ADMIN_SUCCESS = "DELETE_FUNCTIONAL_ADMIN_SUCCESS";
export const DELETE_FUNCTIONAL_ADMIN = "DELETE_FUNCTIONAL_ADMIN";

export const GET_FUNCTIONAL_ADMIN_LIST = "GET_FUNCTIONAL_ADMIN_LIST";
export const GET_FUNCTIONAL_ADMIN_LIST_SUCCESS = "GET_FUNCTIONAL_ADMIN_LIST_SUCCESS";

export const GET_FUNCTIONAL_NONAVAILABLE_ADMIN_LIST = "GET_FUNCTIONAL_NONAVAILABLE_ADMIN_LIST";

export const TOGGLE_ADMIN = "TOGGLE_ADMIN";
export const TOGGLE_ADMIN_SUCCESS = "TOGGLE_ADMIN_SUCCESS";


export const DELETE_ADMINS_INFORMATION = "DELETE_ADMINS_INFORMATION";
export const DELETE_ADMINS_INFORMATION_SUCCESS = "DELETE_ADMINS_INFORMATION_SUCCESS";


export const AVAILABLE_ADMIN = "AVAILABLE_ADMIN";
export const NON_AVAILABLE_ADMIN = "NON_AVAILABLE_ADMIN";

export const ENABLE_EDIT = "ENABLE_EDIT";
export const DISABLE_EDIT = "DISABLE_EDIT";

export const GET_MEDECIN_ADMIN = "GET_MEDECIN_ADMIN";
export const GET_MEDECIN_ADMIN_SUCCESS= "GET_MEDECIN_ADMIN_SUCCESS";

export const RETRIEVE_ADMIN = "RETRIEVE_ADMIN";
export const RETRIEVE_ADMIN_SUCCESS = "RETRIEVE_ADMIN_SUCCESS"

