import React from "react";
import { Redirect } from "react-router-dom";
// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";

import ForgetPwd from "../pages/Authentication/ForgetPassword";
// Dashboard
import Dashboard from "../pages/Dashboard/index";

import EditAdmins from "../pages/Admins/edit";
import adminlist from "../pages/Admins/adminlist";

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/admins/:id", component: EditAdmins },
  { path: "/admins", component: adminlist },
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];
const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
];

export { authProtectedRoutes, publicRoutes };
