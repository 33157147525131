import React, { useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Media,
} from "reactstrap";

import { Link } from "react-router-dom";
// Pages Components

import Logs from "./Logs";


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { getAdmins } from "../../store/admins/actions";
import { useDispatch, useSelector } from "react-redux";

//i18n
import { withNamespaces } from "react-i18next";

const Dashboard = () => {
  const dispatch = useDispatch();

 
  const { baseList } = useSelector(({ Admins }) => Admins);

  useEffect(() => {
    dispatch(getAdmins());
    // dispatch(listRequestTypes());
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs />

          <Row style={{ paddingTop: "40px" }}>
            <Col xl="12">
              <Row>
                {/* Reports Render */}
                <Col md="6">
                  <Card className="mini-stats-wid">
                  
                    <CardBody>
                      <Media>
                        <Media body>
                          <p
                            className="text-muted font-weight-bold"
                            style={{ fontSize: "medium" }}
                          >
                            Medecins Requérants{" "}
                          </p>
                          <h4 className="mb-0"></h4>
                        </Media>
                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                          <span className="avatar-title">
                            <i
                              className="fas fa-user-md"
                              style={{ fontSize: "x-large" }}
                            ></i>
                          </span>
                        </div>
                      </Media>
                      

                      <br></br>
                      <Link to="/admins">
                        <span className="float-right">Voir les détails</span>
                        {baseList?.filter((i)=> i.type==="medecin").length}
                      </Link>
                      
                    </CardBody>
                  </Card>
                </Col>

                {/* <Col md="4">
                  <Card className="mini-stats-wid">
                 */}
                
                        
                           {/*<p
                            className="text-muted font-weight-bold"
                            style={{ fontSize: "medium" }}
                          >
                            {" "}
                            Demandes
                          </p>
                          <h4 className="mb-0"></h4>
                        </Media>
                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                          <span className="avatar-title">
                            <i
                              className="fas fa-clipboard-list"
                              style={{ fontSize: "x-large" }}
                            ></i>
                          </span>
                        </div>*/}
                  
                     
                     
                   
                  {/* </Card>
                </Col> */}
               
                <Col md="6">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <Media>
                        <Media body>
                          <p
                            className="text-muted font-weight-bold"
                            style={{ fontSize: "medium" }}
                          >
                             Medecins Experts{" "} 
                          </p>
                          <h4 className="mb-0"></h4>
                        </Media>
                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                          <span className="avatar-title">
                            <i
                              className="fa fa-address-card"
                              style={{ fontSize: "x-large" }}
                            ></i>
                          </span>
                        </div>
                      </Media>
                      <br></br>
                      <Link to="/admins">
                        <span className="float-right">Voir les détails</span>
                        {baseList?.filter((i)=> i.type==="medecinTeleexpert").length}
                      </Link>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Card></Card>
            </Col>
          </Row>

          <Row>
            <Col lg="6">
              <Logs title="Medecins" list= {baseList?.filter((i)=> i.type==="medecin") }/>
            </Col>
            <Col lg="6">
            <Logs title="Medecins Experts" list= {baseList?.filter((i)=> i.type==="medecinTeleexpert") }/>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withNamespaces()(Dashboard);
