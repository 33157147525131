import React, {Fragment, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { reduxForm } from "redux-form";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  TabContent,
  TabPane,
} from "reactstrap";
import { retrieveAdmin, enableEdit } from "./../../store/admins/actions";
import Information from "./components/information";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import SpinnerPage from "../../components/loader";

const EditAdmins = () => {
  const params = useParams();
  const { id } = params;

  const { selectedAdmin, loading, editMode, stepNumber } = useSelector(
    ({ Admins }) => Admins
  );

  const dispatch = useDispatch();

  const initData = () => {
    if (id != "create" && !editMode) {
      dispatch(enableEdit(id));
      dispatch(retrieveAdmin(id));
    }
  };

  useEffect(() => {
    initData();
  }, []);

  const StepsDisplay = (id) => {
    switch (id) {
      case 0:
        return <Information id={id} />;
      default:
        return null;
    }
  };
  return (
    <Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Admins"
            breadcrumbItem={
              editMode && selectedAdmin && selectedAdmin.firstName
                ? `${selectedAdmin.firstName} ${selectedAdmin.lastName}`
                : "create"
            }
          />
          {loading ? (
            <SpinnerPage />
          ) : (
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <TabContent activeTab={0}>
                      <TabPane tabId={0} className="p-3">
                        {StepsDisplay(stepNumber)}
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </Fragment>
  );
};

export default reduxForm({
  form: "Admins",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(EditAdmins);
