import React from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { withRouter  } from "react-router-dom";

//i18n
import { withNamespaces } from "react-i18next";
import { useState } from "react";

const ProfileMenu = (props) => {
  const [menu, setMenu] = useState(false);


  const toggle = () => {
    setMenu(!menu);
  };

  return (
    <React.Fragment>
      <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block" style={{marginTop:"10px"}}>
      
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
        <p style={{fontFamily:"revert" , fontWeight:"900" ,fontSize:"15px"}}  > Bonjour , {localStorage.getItem('username') } 
                        <div className="arrow-down" ></div> </p>

</DropdownToggle>

<DropdownMenu right>

{<DropdownItem tag="a" href="/dashboard"  >
          <i className="mdi mdi-view-dashboard font-size-16 align-middle mr-1 "></i>
{props.t("Tableau de bord")}
          </DropdownItem> }

{/* {<DropdownItem tag="a" href="/profile">
            <i className="bx bx-user font-size-16 align-middle mr-1"></i>
            {props.t("Profile")}
          </DropdownItem> } */}


          {<DropdownItem tag="a" href="/logout"  >
          <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
{props.t("Se déconnecter")}
          </DropdownItem> }


          {/* <DropdownItem tag="a" href="/crypto-wallet">
            <i className="bx bx-wallet font-size-16 align-middle mr-1"></i>
            {props.t("My Wallet")}
          </DropdownItem> */}
          {/* <DropdownItem tag="a" href="#">
            <span className="badge badge-success float-right">11</span>
            <i className="mdi mdi-settings font-size-17 align-middle mr-1"></i>
            {props.t("Settings")}
          </DropdownItem> */}
          {/* <DropdownItem tag="a" href="auth-lock-screen">
            <i className="bx bx-lock-open font-size-16 align-middle mr-1"></i>
            {props.t("Lock screen")}
          </DropdownItem> */}
          {/* <div className="dropdown-divider"></div>
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
            <span>{props.t("Logout")}</span>
          </Link> */}
        </DropdownMenu> 
      </Dropdown>
    </React.Fragment>
  );
};

export default withRouter(withNamespaces()(ProfileMenu));
