import React, { useState, Fragment, useEffect } from "react";
import { Button, Row, Col, Form, FormGroup, Label } from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Select from "react-select";
import { addAdmin, editAdmin } from "../../../store/admins/actions";

import moment from "moment";
import { startCase } from "lodash";

import FileDropzone from "../../../components/Common/FileDropzone";

import { AVAILABLE_ADMIN } from "../../../store/admins/types";

const genderOptionGroup = [
  {
    label: "Homme",
    value: "male",
  },
  {
    label: "Femme",
    value: "female",
  },
];
const typeOptionGroup = [
  { label: "Medecin Référent", value: "medecinResponsable" },
  { label: "Medecin Expert", value: "medecinTeleexpert" },
  { label: "Medecin Genéraliste", value: "medecin" },
];
const imageBaseUrl = "http://localhost:3009/v1";

const Information = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const availability = eval(localStorage.getItem("availability"));

  const { baseList, selectedAdmin, editMode } = useSelector(
    ({ Admins }) => Admins
  );
  const { register, handleSubmit, control, errors, reset } = useForm();

  const [imageToggle, setImageToggle] = useState(false);
  const [imageToDisplay, setImageToDisplay] = useState(null);

  //toDeleteList : array containing the IDs (as strings) of the objects present in the DB that have been deleted from the map.
  const [toDeleteList, setToDeleteList] = useState([]);
  //Temporarly stocks the most recent deleted item so we can add it to toDeleteList inside onChange.

  useEffect(() => {
    if (editMode) {
      reset({
        ...selectedAdmin,
      });
      setImageToDisplay(`${imageBaseUrl}/${selectedAdmin?.picture}`);
    }
  }, []);

  const onSubmit = (data) => {
    try {
      let formData = new FormData();
      formData.append("firstName", data.firstName);
      formData.append("lastName", data.lastName);
      formData.append("birthday", data.birthday);
      formData.append("phone", data.phone);
      formData.append("email", data.email);
      formData.append(
        "gender",
        !data.gender.value ? selectedAdmin?.gender : data.gender.value
      );
      formData.append(
        "type",
        !data.type.value ? selectedAdmin?.type : data.type.value
      );

      formData.append(
        "picture",
        imageToggle ? data.files[0] : selectedAdmin?.picture
      );
      if (toDeleteList !== []) {
        formData.append("toDeleteList", JSON.stringify(toDeleteList));
      }

      if (editMode) {
        dispatch(
          editAdmin(
            selectedAdmin?._id,
            formData,
            availability ? baseList : [],
            AVAILABLE_ADMIN
          )
        );
      } else {
        localStorage.setItem("availability", true);
        dispatch(addAdmin(formData, baseList));
      }
      history.push(`/admins`);
    } catch (error) {
      console.error(error);
    }
  };

  const handleResetLogo = () => {
    setImageToggle(false);
    setImageToDisplay(null);
    // !editMode && reset({...getValues(), files: null })
  };

  return (
    <Fragment>
      <Form>
        <Row>
          <Col sm="12">
            <h4>Informations personnelles</h4>
          </Col>
        </Row>
        <Row>
          <Col sm="8">
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label htmlFor="firstName">Prénom</Label>
                  <input
                    name="firstName"
                    type="text"
                    className="form-control"
                    ref={register({
                      required: "Prenom est obligatoire",
                      minLength: {
                        value: 3,
                        message: "Le prénom doit etre superieur ou egal à 3",
                      },
                      maxLength: {
                        value: 20,
                        message: "Le prénom doit etre inferieur ou egal à 20",
                      },
                    })}
                  />
                  <span className="my-2 text-danger">
                    {errors.firstName && errors.firstName?.message}
                  </span>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label htmlFor="lastName">Nom</Label>
                  <input
                    name="lastName"
                    type="text"
                    className="form-control"
                    ref={register({
                      required: "Nom est obligatoire",
                      minLength: {
                        value: 3,
                        message: "Le nom doit etre superieur ou egal à 4",
                      },
                      maxLength: {
                        value: 10,
                        message: "Le nom doit etre inferieur ou egal à 10",
                      },
                    })}
                  />
                  <span className="my-2 text-danger">
                    {errors.lastName && errors.lastName?.message}
                  </span>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm="6">
                <FormGroup>
                  <Label htmlFor="gender">Genre</Label>
                  <Controller
                    name="gender"
                    control={control}
                    defaultValue={
                      editMode &&
                      genderOptionGroup.find(
                        (elem) => elem.value === selectedAdmin?.gender
                      )
                    }
                    ref={register("gender", {
                      required: "Genre est obligatoire",
                      message: "Genre est obligatoire",
                    })}
                    render={({ onChange, value, name, ref }) => (
                      <Select
                        inputRef={ref}
                        placeholder={
                          editMode && startCase(selectedAdmin?.gender)
                        }
                        classNamePrefix="addl-class"
                        options={genderOptionGroup}
                        value={genderOptionGroup.find((c) => c.value === value)}
                        onChange={(val) => onChange(val)}
                      />
                    )}
                  />
                  <span className="my-2 text-danger">
                    {errors.gender && errors.gender?.message}
                  </span>
                </FormGroup>
              </Col>
              <Col sm="6">
                <FormGroup>
                  <Label htmlFor="type">Type</Label>
                  <Controller
                    name="type"
                    control={control}
                    defaultValue={
                      editMode &&
                      typeOptionGroup.find(
                        (elem) => elem.value === selectedAdmin?.type
                      )
                    }
                    ref={register("type", {
                      required: "Type est obligatoire",
                      message: "Type est obligatoire",
                    })}
                    render={({ onChange, value, name, ref }) => (
                      <Select
                        inputRef={ref}
                        placeholder={editMode && startCase(selectedAdmin?.type)}
                        classNamePrefix="addl-class"
                        options={typeOptionGroup}
                        value={typeOptionGroup.find((c) => c.value === value)}
                        onChange={(val) => onChange(val)}
                      />
                    )}
                  />
                  <span className="my-2 text-danger">
                    {errors.gender && errors.type?.message}
                  </span>
                </FormGroup>
              </Col>
              <Col sm="6">
                <FormGroup>
                  <label>Date de naissance</label>
                  <Controller
                    name="birthday"
                    control={control}
                    rules={{
                      required: "Date de naissance est obligatoire.",
                    }}
                    render={({ onChange, value, name, ref }) => (
                      <input
                        ref={ref}
                        className="form-control"
                        type="date"
                        defaultValue={
                          editMode &&
                          moment(selectedAdmin?.birthday)
                            .format("YYYY-MM-DD")
                            .toString()
                        }
                        id="date-input"
                        onChange={(val) => onChange(val)}
                      />
                    )}
                  />
                  <span className="my-2 text-danger">
                    {errors.birthday && errors.birthday?.message}
                  </span>
                </FormGroup>
              </Col>
            </Row>
          </Col>
          <Col sm="4">
            <Row className="mb-1">
              <Col sm="10">
                <label>Image</label>
              </Col>

              <Col sm="1">
                <Button
                  type="button"
                  className="badge bg-danger ms-2 p-1"
                  style={{ color: "white" }}
                >
                  <i className="fas fa-trash-alt" onClick={handleResetLogo} />
                </Button>
              </Col>
            </Row>
            <Row>
              <Controller
                name="files"
                rules={{ required: !editMode }}
                control={control}
                defaultValue={imageToDisplay}
                render={({ onChange, value, ref }) => (
                  <FileDropzone
                    imageToDisplay={imageToDisplay}
                    setImageToDisplay={setImageToDisplay}
                    setImageToggle={setImageToggle}
                    onChange={onChange}
                  />
                )}
              />
            </Row>

            {errors.files && (
              <span className="my-2 text-danger">
                Ce champ est obligatoire.
              </span>
            )}
          </Col>
        </Row>
        {/* <Row>
          <Col sm="12">
     
          </Col>
        </Row> */}
        <Row className="mb-5"></Row>
        <Row>
          <Col sm="12">
            <h4>Informations de connexion</h4>
          </Col>
          <Col sm="5">
            <FormGroup>
              <Label htmlFor="email">Email</Label>
              <input
                className="form-control"
                type="email"
                name="email"
                ref={register({
                  required: "Email est obligatoire",
                  minLength: {
                    value: 4,
                    message: "L'email doit etre superieur ou egale à 4",
                  },
                })}
              />
              <span className="my-2 text-danger">
                {errors.email && errors.email?.message}
              </span>
            </FormGroup>
          </Col>

          <Col sm="5">
            <FormGroup>
              <Label htmlFor="phone">Num.Téléphone</Label>
              <input
                className="form-control"
                type="tel"
                name="phone"
                ref={register({
                  required: "Numero de telephone est obligatoire",
                  minLength: {
                    value: 8,
                    message:
                      "Le numero de telephone doit etre superieur ou egale à 4",
                  },
                })}
              />
              <span className="my-2 text-danger">
                {errors.phone && errors.phone?.message}
              </span>
            </FormGroup>
          </Col>
        </Row>
        <Button
          type="button"
          onClick={handleSubmit(onSubmit)}
          color="primary"
          className="mr-1 waves-effect waves-light"
        >
          Valider
        </Button>
      </Form>
    </Fragment>
  );
};

export default Information;
