import React from 'react';
import {
    ModalHeader,
    ModalBody,
    Button
} from "reactstrap";

/**
 * Props :
 * data => is the data OBJECT that is going to contain our information.
 * 
 * deleteAction => The delete method (from actions) passed by the parent.
 * 
 * toggleModal => toggles the modal on/off.
 */
const DeleteConfirmation = ({deleteAction ,toggleModal}) => {  
  return(
  <React.Fragment>
    <ModalHeader toggle={toggleModal}>
      Etes vous sur de vouloir supprimez cet element ?
    </ModalHeader>
    <ModalBody>
      <Button
        onClick={() => {
          deleteAction();
          toggleModal();
        }}
        type="button"
        color="success"
        className="waves-effect waves-light float-left"
        >
        ✔️{" "}Confirmer&nbsp;&nbsp;{" "}
      </Button>
      <Button
        onClick={() => {
          toggleModal();
        }}
        type="button"
        color="danger"
        className="waves-effect waves-light float-right"
        >
        ❌{" "}Annuler&nbsp;&nbsp;{" "}
      </Button>
    </ModalBody>
  </React.Fragment>    
  )
}

export default DeleteConfirmation;