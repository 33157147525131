import {
  GET_FUNCTIONAL_ADMIN_LIST,
  GET_FUNCTIONAL_ADMIN_LIST_SUCCESS,
  ADD_FUNCTIONAL_ADMIN,
  ADD_FUNCTIONAL_ADMIN_SUCCESS,
  EDIT_FUNCTIONAL_ADMIN_SUCCESS,
  EDIT_FUNCTIONAL_AMDIN,
  TOGGLE_ADMIN,
  TOGGLE_ADMIN_SUCCESS,
  DELETE_ADMINS_INFORMATION,
  DELETE_ADMINS_INFORMATION_SUCCESS,
  ENABLE_EDIT,
  DISABLE_EDIT,
  RETRIEVE_ADMIN,
  RETRIEVE_ADMIN_SUCCESS,
  GET_MEDECIN_ADMIN,
  GET_MEDECIN_ADMIN_SUCCESS,
} from "./types";

export const getAdmins = () => {
  return {
    type: GET_FUNCTIONAL_ADMIN_LIST,
  };
};

export const getAdminsSuccess = (data) => {
  return {
    type: GET_FUNCTIONAL_ADMIN_LIST_SUCCESS,
    payload: data,
  };
};

export const getMedecinAdmins = () => {
  return {
    type: GET_MEDECIN_ADMIN,
  };
};

export const getMedecinAdminsSuccess = (data) => {
  return {
    type: GET_MEDECIN_ADMIN_SUCCESS,
    payload: data,
  };
};

// export const getNonAvailableAdmins = () => {
//   return {
//     type: GET_FUNCTIONAL_NONAVAILABLE_ADMIN_LIST,
//   };
// };

// export const getNonAvailableAdminsSuccess = (data) => {
//   return {
//     type: GET_FUNCTIONAL_NONAVAILABLE_ADMIN_LIST_SUCCESS,
//     payload: data,
//   };
// };

export const toggleAdmin = (data, adminType) => {
  return {
    type: TOGGLE_ADMIN,
    payload: {
      data,
      adminType,
    },
  };
};

export const toggleAdminSuccess = (data) => {
  return {
    type: TOGGLE_ADMIN_SUCCESS,
    payload: data,
  };
};


export const addAdmin = (adminData, oldList) => {
  return {
    type: ADD_FUNCTIONAL_ADMIN,
    payload: {
      adminData,
      oldList,
    },
  };
};

export const addAdminSuccess = (data) => {
  return {
    type: ADD_FUNCTIONAL_ADMIN_SUCCESS,
    payload: data,
  };
};

export const editAdmin = (id, adminData, oldList, adminType) => {
  return {
    type: EDIT_FUNCTIONAL_AMDIN,
    payload: {
      id,
      adminData,
      oldList,
      adminType,
    },
  };
};

export const editAdminSuccess = (data) => {
  return {
    type: EDIT_FUNCTIONAL_ADMIN_SUCCESS,
    payload: data,
  };
};

export const retrieveAdmin = (id) => {
  return {
    type: RETRIEVE_ADMIN,
    payload: id,
  };
};
export const retrieveAdminSuccess = (data) => {
  return {
    type: RETRIEVE_ADMIN_SUCCESS,
    payload: data,
  };
};

export const enableEdit = (id) => {
  return {
    type: ENABLE_EDIT,
    payload: id,
  };
};
export const disableEdit = () => {
  return {
    type: DISABLE_EDIT,
  };
};

export const deleteAdminsInformation = (id, oldList, adminType) => {
  return {
    type: DELETE_ADMINS_INFORMATION,
    payload: {
      id,
      oldList,
      adminType,
    },
  };
};
export const deleteAdminsInformationSuccess = (payload) => {
  return {
    type: DELETE_ADMINS_INFORMATION_SUCCESS,
    payload: payload,
  };
};
