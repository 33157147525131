import React, { useEffect, useState } from "react";

import { Card, CardBody, CardTitle} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
//Actions

import { getAdmins } from "../../store/admins/actions";
const Logs = (props) => {
  const dispatch = useDispatch();
  const { baseList, loading } = useSelector(({ Admins }) => Admins);
  useEffect(() => {
    dispatch(getAdmins());
  }, []);
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-4">
            Liste des {props.title}
          </CardTitle>
          <div className="table-responsive">
            <table className="table table-centered table-nowrap mb-0">
              <thead className="thead-light">
                <tr>
                  <th>Nom</th>
                  <th>Email</th>
                  <th>Numéro de téléphone </th>
                </tr>
              </thead>
              <tbody>
                {props.list.map((item) => (
                  <tr>
                    <td style={{ fontWeight: "bold" }}>{item.firstName}&nbsp;{item.lastName} </td>
                    <td> {item.email} </td>
                    <td> {item.phone} </td>
                  </tr>
                ))}

                {/* })} */}
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default Logs;
