import { all } from "redux-saga/effects";

//public
import LayoutSaga from "./layout/saga";
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import AdminSaga from "./admins/sagas";
export default function* rootSaga() {
  yield all([
 
    AccountSaga(),
    AuthSaga(),
    ForgetSaga(),
    LayoutSaga(),
    AdminSaga(),
 
    
  ]);
}
